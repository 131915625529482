@charset "UTF-8";
@import "_mixin";

/* ===================================
 * button
=================================== */

.btn__wrap{
  text-align:center;
}

/* ===================================
 * button
=================================== */

//class名:(background-color, border-color, color)
$buttons: (
    btn--default  : ($colorGrayDark,$colorGrayDark,#ffffff),
    btn--default-w: (none,$colorGrayDark,$colorGrayDark),
    btn--action   : ($colorPrimary,$colorPrimary,#ffffff),
    btn--action-w : (#ffffff,$colorPrimary,$colorPrimary),
);
@mixin btn--(){
  display: inline-block;
  min-width:220px;
  box-sizing: border-box;
  outline: none;
  //border-radius: 4px;
  padding: 0.5em 2em 0.5em 1em;
  text-align: center;
  text-decoration: none;
  position:relative;
  @include fontsize();
  cursor: pointer;
}
@each $key, $value in $buttons {
    .#{$key} ,
    .#{$key}:link ,
    .#{$key}:visited,
    .#{$key}:active {
        @include btn--();
        background-color: nth($value, 1);
        border: 1px solid nth($value, 2);
        color: nth($value, 3);
        &:hover{
          opacity: 0.7;
          text-decoration: none;
        }
        &.is-disabled,
        &.is-disabled:hover{
          opacity: 0.3;
          cursor: default;
        }
        &::after{
          content:"";
          width:10px;
          height:10px;
          border-top:1px solid nth($value, 3);
          border-right:1px solid nth($value, 3);
          transform: rotate(45deg);
          position:absolute;
          top:50%;
          margin-top:-5px;
          right:16px;
        }
        &.is-l{
          @include fontsize(24);
          padding: 1em 3em 1em 2em;
          &::after{
            content:"";
            border-width:2px;
            width:14px;
            height:14px;
            margin-top:-7px;
            right:23px;
          }
        }
    }
}

@media screen and (max-width: $sm-width) {

  @each $key, $value in $buttons {
      .#{$key} ,
      .#{$key}:link ,
      .#{$key}:visited,
      .#{$key}:active {
          display:block;
          width:100%;
          &.is-l{
            padding: 1em 1.6em 1em 0.8em;
            @include fontsize(24);
            &::after{
              right:16px;
            }
          }
      }
  }

}

/* ===================================
 * button
=================================== */

//class名:(background-color, border-color, color)
$snsButtons: (
    btn--facebook  : (#3B5998,#3B5998,#fff),
    btn--twitter: (#1DA1F2,#1DA1F2,#fff),
    btn--linkedin   : (#0077B5,#0077B5,#fff),
    btn--pinterest : (#BD081C,#BD081C,#fff),
    btn--google: (#DB4437,#DB4437,#fff),
);
@mixin snsbtn--(){
  display: inline-block;
  box-sizing: border-box;
  outline: none;
  //border-radius: 4px;
  padding: 0.5em 2em 0.5em 1em;
  text-align: center;
  text-decoration: none;
  position:relative;
  @include fontsize();
  cursor: pointer;
}
@each $key, $value in $snsButtons {
    .#{$key} ,
    .#{$key}:link ,
    .#{$key}:visited,
    .#{$key}:active {
        @include snsbtn--();
        background-color: nth($value, 1);
        border: 1px solid nth($value, 2);
        color: nth($value, 3);
        &:hover{
          opacity: 0.7;
          text-decoration: none;
        }
        &::after{
          content:"";
          width:10px;
          height:10px;
          border-top:1px solid nth($value, 3);
          border-right:1px solid nth($value, 3);
          transform: rotate(45deg);
          position:absolute;
          top:50%;
          margin-top:-5px;
          right:16px;
        }
    }
}
