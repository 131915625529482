@charset "UTF-8";

// ===================================
//  各scssで使用する変数をここで定義
// ===================================

// layout
//どれか一つ選ぶ
// -----------------------------------

//1カラム幅：47px
//$max-width:1048px;
//$margin:44px;

//1カラム幅：43px
//$max-width:967px;
//$margin:41px;

//1カラム幅：48px
//$max-width:972px;
//$margin:36px;

//1カラム幅：62px
//$max-width:1063px;
//$margin:29px;

//1カラム幅：58px
//$max-width:982px;
//$margin:26px;

//1カラム幅：60px
$max-width:984px; //最大幅 984 or 1128 だとmargin:24で綺麗におさまる
$margin:24px; //カラム間余白

//タブレットビューに切り替わるbreakpoint
$tb-width:$max-width;

//スマホビューに切り替わるbreakpoint
$sm-width:720px;

// fontsize
// -----------------------------------
$bodyFontsize:16;
$bodyLineheight:22;
@mixin fontsize($size:$bodyFontsize, $height:$bodyLineheight) {
  $rem: $size / $bodyFontsize;
  $linS: $height / $size;
  font-size: #{$size}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}


// color
// -----------------------------------

//テーマカラー
$colorMain:#f95c32;
$colorLine:#33c048;
$colorTakuhai:#337bd1;
$colorTentou:#d19433;
$colorShutyou:#d13333;

$colorFont:#4a4a4a;

$colorGrayDark:#4a4a4a;
$colorGrayMiddle:#888;
$colorGray:#efedeb;

$colorGold:#dbb258;
$colorPt:#e0eaec;
$colorSilver:#d0d2de;


//重要：送信ボタンなどに使用
$colorPrimary:#f95c32;
$colorPrimaryBg:#d9edf7;

//成功：保存成功に使用
$colorSuccess:#3c763d;
$colorSuccessBg:#dff0d8;

//注意：
$colorCaution:#8a6d3b;
$colorCautionBg:#fcf8e3;

//警告：エラーなどで使用
$colorWarning:#a94442;
$colorWarningBg:#f2dede;
