@charset "UTF-8";
@import "_mixin";

.form__require{
  display:inline-block;
  padding:1px 4px;
  margin-left:8px;
  border:1px solid $colorMain;
  color:$colorMain;
  font-size:12px;
  font-weight:normal;
  position:relative;
  top:-2px;
  white-space: nowrap;
}
/* ===================================
 * 行定義
=================================== */
.form__row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.form__row.is-center{
  justify-content: center;
}

/* ===================================
 * 列定義
=================================== */
.form__col {
  display: block;
  width: 100%;  //flex-item なので横幅を明示
  position: relative;
}

.form__col + .form__col{
  margin-top: 8px;
}

//modifireはここに登録。横幅100%にしたいならf上記orm__colを使うこと
$formCols: (
    form__col--inline: auto,
    form__col--name: 200px,
    form__col--fullname: 400px,
    form__col--tel: 400px,
    form__col--email: 400px,
    form__col--zip: 120px,
    form__col--zip1: 40px,
    form__col--zip2: 60px,
    form__col--address: 400px,
    form__col--search: 178px,
    form__col--searchbtn: 50px,
);
@mixin form__col--(){
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
}
@each $key, $value in $formCols {
    .#{$key} {
        @include form__col--();
        @if $key == form__col--search {
          margin-right:0;
        }
        width: $value;
    }
}
@media screen and (max-width: $sm-width) {
  .form__col--inline{
    margin-right:10px;
  }
  .form__col--name,
  .form__col--fullname,
  .form__col--tel,
  .form__col--email,
  .form__col--address,
  .form__col--search,
  .form__col--searchbtn{
    width:auto;
    max-width:100%;
  }
}

/* ===================================
 * input, select, textarea
=================================== */
.form__input ,
.form__select,
.form__textarea,
.form__submit{
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
  padding: 0.5em 0.5em;
  border-radius: 4px;
  @include fontsize();
  outline: none;
  background:#ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;//autocompleteの黄色対策
  appearance: none;
  -webkit-appearance:none;
  resize: vertical;
  box-sizing: border-box;
  &:-webkit-autofill{
    background-color:#ffffff;
  }
}
.form__input.is-search{
  border-radius: 4px 0 0 4px;
  border-right:none;
  @include fontsize(14);
}
.form__submit.is-search{
  border-radius: 0 4px 4px 0;
  @include fontsize(14);
}

.form__input:disabled ,
.form__select:disabled,
.form__textarea:disabled {
  background: #f0f0f0;
  color: #bbbbbb;
  cursor: default;
}

.form__input.is-error ,
.form__select.is-error,
.form__textarea.is-error{
  border-color:$colorWarning;
}

/* input
----------------------------------- */
.form__input::placeholder {
  color: #bbbbbb;
}

/* select
----------------------------------- */
.form__selectwrap {
  position: relative;
  display: inline-block;
}
.form__selectwrap:before {
  content: '';
  width: 6px;
  height: 6px;
  border: 0px;
  border-top: solid 2px #cccccc;
  border-right: solid 2px #cccccc;
  transform: rotate(135deg);
  position: absolute;
  top: 50%;
  right: 0.8rem;
  margin-top: -0.3rem;
  z-index: 2;
}

.form__select {
  padding-right: 2em;
}

.form__select::-ms-expand {
  display:none;
}

.form__select.utl__fs--sm {
  padding: 3px 2rem 1px 8px;
}

/* textarea
----------------------------------- */
.form__textarea {
  height: 12rem;
}
.form__textarea::-webkit-input-placeholder{
  color:#bbb;
}
.form__textarea:-ms-input-placeholder{
  color:#bbb;
}
.form__textarea::-moz-placeholder{
  color:#bbb;
}
/* ===================================
 * file
=================================== */
.form__file {
  display:none;
}
.form__filewrap{
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
  padding: 0.5em 0.5em;
  border-radius: 4px;
  @include fontsize();
  outline: none;
  background:#ffffff;
  appearance: none;
  -webkit-appearance:none;
  resize: vertical;
  cursor:pointer !important;
}

.form__filewrap.is-error{
  border-color:$colorWarning;
}


/* ===================================
 * .form__radio, form__checkbox
=================================== */
input[type="radio"],
.form__checkbox {
  display: none;
}
input[type="radio"] + .wpcf7-list-item-label,
.form__checkbox + label {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.25em 0 0.25em 28px;
  position:relative;
  @include fontsize();
  outline: none;
  cursor: pointer !important;
}

input[type="radio"] + .wpcf7-list-item-label:empty,
.form__checkbox + label:empty {
  padding-left: 20px;
}

input[type="radio"] + .wpcf7-list-item-label:hover,
.form__checkbox + label:hover {
  opacity: 0.7;
}

input[type="radio"]:disabled + .wpcf7-list-item-label,
input[type="radio"]:disabled + .wpcf7-list-item-label:hover,
.form__checkbox:disabled + label,
.form__checkbox:disabled + label:hover {
  opacity: 0.3;
  cursor: default;
}

input[type="radio"]:disabled + .wpcf7-list-item-label::before,
.form__radio:disabled + label:hover::before {
  background: #e0e0e0;
  cursor: default;
}

input[type="radio"].is-error + .wpcf7-list-item-label::before,
.form__checkbox.is-error + label::before{
  border-color:$colorWarning;
}

/* form__radio
----------------------------------- */

input[type="radio"] + .wpcf7-list-item-label::before{
  content: "";
  display: block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #cccccc;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 0;
}

input[type="radio"]:checked + .wpcf7-list-item-label::before{
  border:6px solid $colorPrimary;
}



/* form__checkbox
----------------------------------- */
.form__checkbox + label::before {
  content: "";
  display: block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #cccccc;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 0;
}

.form__checkbox:checked + label::before {
  border: 1px solid $colorPrimary;
  background: $colorPrimary;
}

.form__checkbox:checked + label::after {
  content: '';
  width: 10px;
  height: 6px;
  border: 0px;
  border-top: solid 2px #ffffff;
  border-right: solid 2px #ffffff;
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  position: absolute;
  top: 50%;
  left: 4px;
  margin-top: -0.35rem;
  z-index: 2;
}

.form__checkbox:disabled + label::before,
.form__checkbox:disabled + label:hover::before {
  background: #e0e0e0;
  cursor: default;
}

.form__checkbox.is-error + label::before{
  border-color:$colorWarning;
}

/* ===================================
 * .form__checkbox--sw
=================================== */
.form__checkbox--sw {
  display: none;
}

.form__checkbox--sw + label {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.25em;
  padding-right: calc(1.375em + 4px + 4px + 4px);  // lineheight + padding-top + padding-bottom + 余白
  border-radius: 20px;
  outline: none;
  cursor: pointer !important;
  background: $colorPrimary;
  color: #ffffff;
  border: 1px solid $colorPrimary;
}

.form__checkbox--sw + label::before {
  transition: all 300ms 0s ease;
  content: "";
  display: block;
  box-sizing: border-box;
  width: calc(1.375em + 4px + 4px);  // lineheight(debugtoolで確認) + padding-top + padding-bottom
  height: calc(1.375em + 4px + 4px);  // lineheight(debugtoolで確認) + padding-top + padding-bottom
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid $colorPrimary;
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: calc(-1 * (1.375em + 4px + 4px + 1px)/ 2);  // height + 余白1px / 2
  margin-left: calc(-1 * (1.375em + 4px + 4px + 1px));  // width ＋ 余白1px
}

.form__checkbox--sw + label::after {
  transition: all 300ms 0s ease;
  content: attr(data-disabled);
  display: block;
  box-sizing: border-box;
  text-align: center;
}

.form__checkbox--sw:checked + label {
  border-color: #cccccc;
  background: #cccccc;
  color: #000000;
  padding: 4px;
  padding-left: calc(1.6em + 4px + 4px + 4px);  // lineheight + padding-top + padding-bottom + 余白
}

.form__checkbox--sw:checked + label::before {
  border-color: #cccccc;
  transition: all 300ms 0s ease;
  left: 0;
  margin-left: 2px;
}

.form__checkbox--sw:checked + label::after {
  content: attr(data-enabled);
}

.form__checkbox--sw + label:hover {
  opacity: 0.7;
}

.form__checkbox--sw:disabled + label,
.form__checkbox--sw:disabled + label:hover {
  opacity: 0.3;
  cursor: default;
}

.form__checkbox--sw:disabled + label::before,
.form__checkbox--sw:disabled + label:hover::before {
  background: #e0e0e0;
  cursor: default;
}

/* ===================================
 * .form__checkbox--sw2
=================================== */
.form__checkbox--sw2 {
  display: none;
}

.form__checkbox--sw2 + label {
  display: inline-block;
  box-sizing: border-box;
  width: 36px;
  padding: 0.35em;
  border-radius: 20px;
  outline: none;
  cursor: pointer !important;
  background: rgba(#bbbbbb, 0.5);
}

.form__checkbox--sw2 + label::before {
  transition: all 100ms 0s ease;
  content: "";
  display: block;
  box-sizing: border-box;
  width: calc(1.375em );  // lineheight(debugtoolで確認)
  height: calc(1.375em );  // lineheight(debugtoolで確認)
  border-radius: 50%;
  background: #bbbbbb;
  border: 1px solid #bbbbbb;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: calc(-1 * (1.375em + 1px)/ 2);  // height + 余白1px / 2
  margin-left: 0;
}

.form__checkbox--sw2:checked + label {
  background: rgba($colorPrimary,0.5);
}

.form__checkbox--sw2:checked + label::before {
  border-color: $colorPrimary;
  background: $colorPrimary;
  transition: all 100ms 0s ease;
  left: 100%;
  margin-left: calc(-1 * (1.375em));  // width ＋ 余白1px
}

.form__checkbox--sw2 + label:hover {
  opacity: 0.7;
}

.form__checkbox--sw2:disabled + label,
.form__checkbox--sw2:disabled + label:hover {
  opacity: 0.3;
  cursor: default;
}

.form__checkbox--sw2:disabled + label::before,
.form__checkbox--sw2:disabled + label:hover::before {
  background: #e0e0e0;
  cursor: default;
}

/* ===================================
 * 確認画面
=================================== */
body[rel="body--step2"] .form__input,
body[rel="body--step2"] .form__select,
body[rel="body--step2"] .form__textarea,
body[rel="body--step2"] .form__input:disabled,
body[rel="body--step2"] .form__select:disabled,
body[rel="body--step2"] .form__textarea:disabled,
body[rel="body--step2"] .form__input:-webkit-autofill,
body[rel="body--step2"] .form__select:-webkit-autofill,
body[rel="body--step2"] .form__textarea:-webkit-autofill,
body[rel="body--step2"] .wpcf7c-conf{
  border:none !important;
  background-color:#fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  color:#4a4a4a;
  padding-left:0;
  padding-right:0;
}
body[rel="body--step2"] .form__selectwrap::before,
body[rel="body--step2"] .form__radio:disabled .form__label,
body[rel="body--step2"] .form__require{
  display:none !important;
}
body[rel="body--step2"] .form__input::placeholder ,
body[rel="body--step2"] .form__textarea::placeholder {
  color: #fff;
}
