@charset "UTF-8";
@import "_mixin";

@import "common";
@import "form";
@import "button";

//===========================================
// .slider
//===========================================
.slider{

}
.slider__item{
  float:left;
  width:calc(100% - 24px);
  margin-left:12px;
  margin-right:12px;
}
.slider__img{
  display:block;
  width:100%;
  height:auto;
  max-width:100%;

}
@media screen and (max-width: $tb-width) {

  .slider__item{
    float:none;
    width:100%;
    margin-left:0;
    margin-right:0;
  }
}


//===========================================
// .point
//===========================================

.point__card{
  background:#fff;
  position:relative;
  &::after{
    content: "";
    position:absolute;
    left:-13px;
    top:30px;
    width:88px;
    height:0px;
    border-top:1px solid $colorMain;
    transform: rotate(-45deg);
  }
}
.point__card-num{
  position:absolute;
  top:6px;
  left:12px;
  font-weight:bold;
  color:$colorMain;
  font-size:20px;
}
.point__card-ttl{
  text-align:center;
  font-size:20px;
  font-weight:bold;
  padding:16px 12px 0;
}
.point__card-txt{
  padding:16px 16px 16px;
  margin:0;
}
.point__img{
  text-align:center;
}
//===========================================
// .genre
//===========================================

.genre__img{
  text-align:center;
}
.genre__ttl{
  font-weight:bold;
  margin:4px 0 0;
}
.genre__txt{
  margin:4px 0 0;
}

@media screen and (max-width: $tb-width) {
  .genre__img > img{
    display:block;
    width:100%;
    max-width:100%;
    margin:0 auto;
  }
}
@media screen and (max-width: $sm-width) {
  .genre::after{
    content:"";
    display:block;
    clear:both;
  }
  .genre__img{
    width:25%;
    float:left;
  }
  .genre__ttl,
  .genre__txt{
    width:calc(75% - 24px);
    float:right;
    text-align:left !important;
  }
}

//===========================================
// .item
//===========================================

.item__img{
  text-align:center;
  &.is-jewelry{
    background-color:#ebf3fa;
  }
}
.item__name{
  margin:12px 0 0;
  font-weight:bold;
  &.is-small{
    @include fontsize(14,20);
  }
}
.item__tags{
  margin:12px 0 0;
  padding:0;
}
.item__tag-type{
  margin:0;
  padding:4px 12px;
  color:#fff;
  display:inline-block;
  &.is-takuhai{
    background-color:$colorTakuhai;
  }
  &.is-tentou{
    background-color:$colorTentou;
  }
  &.is-shutyou{
    background-color:$colorShutyou;
  }
}
.item__tag-pref{
  padding:4px 12px;
  font-weight:bold;
  display:inline-block;
}
.item__price{
  margin:4px 0 0;
  padding:0;
}

.item__price-ttl{
  margin:0;
  padding:0;
  display:inline-block;
}
.item__price-num{
  padding:0;
  margin:0;
  font-size:20px;
  color:$colorMain;
  font-weight:bold;
  display:inline-block;
}
.item__txt{
  margin:4px 0 0;
}

@media screen and (max-width: $tb-width) {
  .item__img > img{
    display:block;
    width:100%;
    max-width:100%;
    margin:0 auto;
  }
}


//===========================================
// .flow
//===========================================
.flow{
  min-height:300px;
  display:flex;
  justify-content:space-between;
}
.flow__item{
  width:22.35%;
  position:relative;
  &:not(:last-child)::before{
    content: "";
    display:block;
    width:0;
    height:100%;
    border-left:1px solid $colorGrayMiddle;
    position:absolute;
    top:0;
    right:-10px;
  }
  &:not(:last-child)::after{
    content: "";
    display:block;
    width:20px;
    height:20px;
    border-top:1px solid $colorGrayMiddle;
    border-right:1px solid $colorGrayMiddle;
    position:absolute;
    top:50%;
    right:-21px;
    margin-top:-21px;
    transform: rotate(45deg);
    background:#fff;
  }
}
.flow__step{
  font-weight:bold;
  color:$colorMain;
}
.flow__ttl{
  font-weight:bold;
  font-size:20px;
  margin-top:4px;
}
.flow__img{
  margin-top:4px;
  text-align:center;
}
.flow__txt{
  margin:4px 12px 0 0;
}
.flow__txt--small{
  margin:4px 12px 0 0;
  font-size:12px;
}

@media screen and (max-width: $sm-width) {
  .flow{
    display:block;
  }
  .flow__item{
    width:100%;
    &:not(:first-child){
      margin-top:24px;
    }
    &:not(:last-child)::before{
      content: "";
      display:block;
      width:100%;
      height:0;
      border-bottom:1px solid $colorGrayMiddle;
      position:absolute;
      top:auto;
      left:0;
      bottom:-10px;
    }
    &:not(:last-child)::after{
      content: "";
      display:block;
      width:20px;
      height:20px;
      position:absolute;
      top:auto;
      margin-top:0;
      bottom:-21px;
      right:50%;
      margin-right:-10px;
      transform: rotate(135deg);
      background:#fff;
    }
  }
}
//===========================================
// .faq
//===========================================
.faq{
  width:816px;
  margin:0 auto;
}
.faq__item{
  padding:20px 0;
  &:not(:last-child){
    border-bottom:1px solid $colorGray;
  }
}
.faq__ttl{
  padding:0 0 0 40px;
  font-size:20px;
  font-weight:bold;
  position:relative;
  &::before{
    display:block;
    width:40px;
    font-size:20px;
    font-weight:bold;
    content:"Q.";
    text-align:center;
    position:absolute;
    top:0;
    left:0;
  }
}
.faq__txt{
  margin-top:12px;
  padding:0 0 0 40px;
  position:relative;
  &::before{
    display:block;
    width:40px;
    content:"A.";
    text-align:center;
    position:absolute;
    top:0;
    left:0;
  }
}
.faq__txt--small{
  font-size:12px;
}

@media screen and (max-width: $tb-width) {
  .faq{
    width:100%;
  }
}


//===========================================
// .step
//===========================================
.step{

}
.step__inner{
  width:648px;
  margin:0 auto;
  display:flex;
  justify-content: space-between;
  padding:0;
  position:relative;
  &:after{
    content: "";
    position: absolute;
    left:100px;
    right:100px;
    bottom:12px;
    height:1px;
    background: $colorGray;
  }
}
.step__li{
  width:100%;
  text-align:center;
  padding:0 0 32px;
  position:relative;
  list-style:none;
  &:after{
    content: "";
    position:absolute;
    bottom:0;
    left:50%;
    margin-left:-12px;
    width:24px;
    height:24px;
    border-radius: 50%;
    background:$colorGray;
    z-index:2;
  }
  &.is-current:after{
    background:$colorMain;
  }
}

body[rel="body--step1"] .step__li--1:after{
  background:$colorMain;
}
body[rel="body--step2"] .step__li--2:after{
  background:$colorMain;
}
body[rel="body--step3"] .step__li--3:after{
  background:$colorMain;
}

@media screen and (max-width: $tb-width) {
  .step__inner{
    width:auto;
    &:after{
      left:calc(100% / 6);
      right:calc(100% / 6);
    }
  }
  .step__span{
    display: none;
  }
}
//===========================================
// .satei
//===========================================
.satei{
  background-color:#fff;
}
.satei__img{
  background-color:#dbb258;
  text-align:center;
  padding:12px 8px;
}
.satei__txt{
  text-align:center;
  margin:0;
  padding:16px;
}


//===========================================
// .satei
//===========================================
.line{
  margin:0 auto;
  width:656px;
  box-sizing: border-box;
  padding:12px;
  border:1px solid $colorLine;
}
.line__ttl{
  background-color:$colorLine;
  color:#fff;
  font-weight:bold;
  padding:8px;
  text-align:center;
}
.line__body{
  margin-top:12px;
  padding-top:12px;
}
.line__left{
  width:128px;
  float:left;
}
.line__qr{
  text-align:center;
}
.line__id{
  text-align:center;
  margin-top:8px;
}
.line__account{
  font-weight:bold;
  color:$colorLine;
}
.line__right{
  float:right;
  width:490px;
}
.line__txt{
  font-weight:bold;
}
.line__list{
  font-size:14px;
  padding-left:20px;
  margin-top:8px;
  list-style:none;
  & > li{
    position:relative;
    &::before{
      content:"※";
      position:absolute;
      top:0;
      left:-1em;
    }
  }
}

@media screen and (max-width: $sm-width) {
  .line{
    width:auto;
  }
  .line__left{
    width:auto;
    float:none;
  }
  .line__right{
    width:auto;
    float:none;
  }
  .line__txt{
    text-align:center;
  }
}


//===========================================
// .kantei
//===========================================
.kantei{
  background:
    url(./img/bg_line_01.png) left bottom no-repeat,
    url(./img/bg_line_02.png) right bottom no-repeat;
}
.kantei__txt{
  width:calc(312px + 312px + 24px);
  margin: 0 auto;
}

@media screen and (max-width: $tb-width) {
  .kantei{
    background:none;
  }
}
@media screen and (max-width: $sm-width) {
  .kantei__txt{
    width:auto;
  }
}
//===========================================
// .flow-line
//===========================================
.flow-line{
  min-height:300px;
  display:flex;
  justify-content:space-between;
}
.flow-line__item{
  width:22.35%;
  position:relative;
  &:not(:last-child)::before{
    content: "";
    display:block;
    width:0;
    height:100%;
    border-left:1px solid $colorGrayMiddle;
    position:absolute;
    top:0;
    right:-10px;
  }
  &:not(:last-child)::after{
    content: "";
    display:block;
    width:20px;
    height:20px;
    border-top:1px solid $colorGrayMiddle;
    border-right:1px solid $colorGrayMiddle;
    position:absolute;
    top:50%;
    right:-21px;
    margin-top:-21px;
    transform: rotate(45deg);
    background:#fff;
  }
}
.flow-line__step{
  font-weight:bold;
  color:$colorLine;
}
.flow-line__ttl{
  font-weight:bold;
  font-size:20px;
  margin-top:4px;
}
.flow-line__img{
  margin-top:12px;
  text-align:center;
}
.flow-line__txt{
  margin:12px 12px 0 0;
}
.flow-line__txt-line{
  color:$colorLine;
  font-weight:bold;
}

@media screen and (max-width: $tb-width) {
  .flow-line__img > img{
    display:block;
    width:100%;
    max-width:100%;
    margin:0 auto;
  }
}
@media screen and (max-width: $sm-width) {
  .flow-line{
    display:block;
  }
  .flow-line__item{
    width:100%;
    &:not(:first-child){
      margin-top:24px;
    }
    &:not(:last-child)::before{
      content: "";
      display:block;
      width:100%;
      height:0;
      border-bottom:1px solid $colorGrayMiddle;
      position:absolute;
      top:auto;
      left:0;
      bottom:-10px;
    }
    &:not(:last-child)::after{
      content: "";
      display:block;
      width:20px;
      height:20px;
      position:absolute;
      top:auto;
      margin-top:0;
      bottom:-21px;
      right:50%;
      margin-right:-10px;
      transform: rotate(135deg);
      background:#fff;
    }
  }
  .flow-line__img > img{
    display:inline;
    width:auto;
    max-width:100%;
  }
}

//===========================================
// .cta
//===========================================
.cta{
  text-align:center;
}
.cta__inner{
  display:inline-block;
  position:relative;
  &:before{
    content: "";
    width:1px;
    height:calc(72px * 1.2);
    position:absolute;
    bottom:0;
    left:24px;
    transform-origin: bottom left;
    transform: rotate(-45deg);
    background-color:#333;
  }
  &:after{
    content: "";
    width:1px;
    height:calc(72px * 1.2);
    position:absolute;
    bottom:0;
    right:24px;
    transform-origin: bottom right;
    transform: rotate(45deg);
    background-color:#333;
  }
}

@media screen and (max-width: $sm-width) {
  .cta__inner{
    &:before{
      display:none;
    }
    &:after{
      display:none;
    }
  }
}

//===========================================
// .post-meta
//===========================================
.post-meta{
  padding:4px 0;
  margin-bottom:24px;
}
.post-meta__ttl{
  @include fontsize(14);
  margin:0 12px 0 0;
  padding:0;
  display:inline-block;
  font-weight:normal;
}
.post-meta__subttl{
  @include fontsize(14);
  display:inline-block;
}

//===========================================
// .post
//===========================================
.post{
  background-color: #fff;
  position:relative;
}
.post + .post{
  margin-top:24px;
}
.post__thumb{
  position:absolute;
  top:0;
  right:0;
  width:225px;
  height:100%;
  overflow: hidden;
}
.post__thumb > a{
  display:block;
  height:100%;
  padding-left:100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.post__thumb > a > img{
  display:none[];
}
.post__header{
  padding:24px 24px 0 24px;
}
.post__ttl{
  @include fontsize(24,36);
  font-weight:bold;
  margin:0;
  padding:0;
}
.post__cat{
  margin:8px 0 0 ;
  padding:0;
  color:$colorGrayMiddle;
}
.post__cat > a{
  @include fontsize(12);
  margin:0 ;
  padding:1px 6px;
  color:#fff;
  background-color: $colorGrayMiddle;
  text-decoration: none;
  display:inline-block;
}
.post__cat > a:hover{
  opacity: 0.7;
}
.post__date{
  @include fontsize(12);
  margin:4px 0 0;
  padding:0;
  color:$colorGrayMiddle;
}
.post__content{
  padding: 0 24px 24px 24px;
}
.post__content > p{
  @include fontsize(16,24);
  margin:8px 0 0;
  padding:0;
}
.post__sns{
  padding: 24px;
}
.post__sns-title{
  text-align:center;
  font-weight:bold;
  @include fontsize(20);
}
.post__sns-body{
  text-align:center;
  margin-top:12px;
}
.post__recommend-title{
  margin-top:48px;
  margin-bottom:24px;
  text-align: center;
  font-weight: bold;
  @include fontsize(20);
}
.post__thumb + .post__header,
.post__thumb + .post__header + .post__content{
  padding-right:250px;
}
@media screen and (max-width: $sm-width) {
  .post{
    display: flex;
    flex-wrap:wrap;
  }
  .post__thumb{
    position:relative;
    width:auto;
    top:auto;
    right:auto;
    order:2;
  }
  .post__header{
    order:1;
    padding-bottom: 24px;
    width:100%;
    box-sizing: border-box;
  }
  .post__content{
    display: none;
    &.is-single{
      display: block;
      order:3;
      width:100%;
      box-sizing: border-box;
    }
  }
  .post__sns{
    order:4;
    width:100%;
    box-sizing: border-box;
  }
  .post__sns--btn{
    width:140px;
    margin:4px 4px 4px 0;
  }
  .post__thumb + .post__header,
  .post__thumb + .post__header + .post__content {
    padding-right: 24px;
  }
}

//===========================================
// .contact-form7
//===========================================
.screen-reader-response,
.wpcf7-validation-errors{
    text-align:center;
    color:$colorMain;
    font-weight:bold;
    margin:24px 0;
}
.wpcf7 .screen-reader-response ul{
  display:none;
}
.wpcf7 .wpcf7-form-control-wrap{
  display:block;
  position:relative;
}
.wpcf7 .wpcf7-not-valid-tip{
  position:absolute;
  top:-30px;
  left:0;
  padding:5px 10px;
  background-color:$colorMain;
  color:#fff;
  @include fontsize(12,16);
  font-weight:normal;
  white-space: nowrap;
  &::before{
    content:"";
    position:absolute;
    left:12px;
    bottom:-10px;
    width:0;
    height:0;
    border-top:10px solid $colorMain;
    border-left:10px solid transparent;
    border-right:10px solid transparent;
  }
}
.wpcf7 .form__row > p,
.wpcf7 .form__row + p{
  display:none;
  margin:0;
}


//===========================================
// .notfound
//===========================================
.notfound{
  background:#fff;
  padding:48px 24px;
}
.notfound__title{
  @include fontsize(24);
  font-weight:bold;
  text-align:center;
}
.notfound__body{
  text-align:center;
  margin-top:24px;
}
