/*
  Theme Name: Gulp WordPress
  Theme URI: http://example.com/
  Author: Author Name
  Author URI: http://example.com/
  Description: Description
  Version: 1.0.0
  License:
  License URI:
  Text Domain: gulp-wordpress
*/
@charset "UTF-8";
@import "_mixin";
html{
  margin:0;
  padding: 0;
  overflow-y: scroll;
}
body {
  margin:0;
  padding: 0;
  font-size:$bodyFontsize#{px};
  color:$colorFont;
  &.body--blog,
  &.body--archive,
  &.body--search,
  &.body--single{
    background-color: $colorGray;
  }
}
.clearfix:after {
  content:"";
  display:block;
  clear:both;
}
a:hover img{
  opacity: 0.7;
}

@media screen and (max-width: $tb-width) {
  body{
    font-family: 'Meiryo',"ヒラギノ角ゴ Pro W3","ＭＳ Ｐゴシック","Osaka", sans-serif;
  }
  input[type="hidden"] + br{
    display:none;
  }
}
//===========================================
// .header
//===========================================

.header{
  min-width:$max-width;
  background-color: #fff;
}
.header__inner{
  width:$max-width;
  margin:0 auto;
}
.header__ttl{
  padding:12px 0;
}
.header__ttl-txt{
  font-size:12px;
  text-decoration: none;
  color:$colorMain;
  cursor:pointer;
  z-index:2;
}
.header__ttl-img{
  margin-top:8px;
  display:inline-block;
  cursor:pointer;
  z-index:2;
}
.header__cta{
  margin:34px 0 ;
  padding:0;
  float:right;
}
.header__cta-li{
  float:left;
  margin-left:8px;
  width:140px;
  text-align:center;
  list-style:none;
}
.header__cta-a{
  display:inline-block;
}

.header__cta-a--{
  display:inline-block;
  min-width:140px;
  border:1px solid #333;
  box-sizing:border-box;
  padding:8px 24px 8px 8px;
  text-align:center;
  position:relative;
  text-decoration: none;
  font-size:14px;
  &::after{
      content:"";
      width:10px;
      height:10px;
      border-top:1px solid #333;
      border-right:1px solid #333;
      transform: rotate(45deg);
      position:absolute;
      top:50%;
      margin-top:-5px;
      right:16px;
  }
}
.header__cta-a--pdf{
  @extend .header__cta-a--;
  border:1px solid $colorFont;
  color: $colorFont;
  &::after{
      border-top:1px solid $colorFont;
      border-right:1px solid $colorFont;
  }
}
.header__cta-a--takuhai{
  @extend .header__cta-a--;
  border:1px solid $colorMain;
  background-color:$colorMain;
  color: #fff;
  &::after{
      border-top:1px solid #fff;
      border-right:1px solid #fff;
  }
}
.header__cta-a--line{
  @extend .header__cta-a--;
  border:1px solid $colorLine;
  color: $colorLine;
  &::after{
      border-top:1px solid $colorLine;
      border-right:1px solid $colorLine;
  }
}
.header__sp-menu{
  display:none;
}

@media screen and (max-width: $tb-width) {
  .header{
    min-width:auto;
  }
  .header__inner{
    width:auto;
  }
  .header .column__col--4of12{
    padding:8px;
    border-bottom:4px solid $colorGrayDark;
    &::after{
      content:"";
      display:block;
      clear:both;
    }
  }
  .header__ttl{
    width:40%;
    max-width:200px;
    float:left;
    padding: 0 8px;
  }
  .header__ttl-txt{
    display:none;
  }
  .header__ttl .header__ttl-img,
  .header__ttl img{
    margin:0;
    display:block;
    height:37px;
    width:auto;
  }
  .header__cta{
    margin:0 ;
    padding:0;
    float:none;
    display:none;
  }
  .header__cta-li{
    float:none;
    margin-left:0;
    width:auto;
    text-align:left;
    list-style:none;
  }
  .header__cta-a--pdf{
    display:block;
    text-align:left;
    padding:14px 12px 14px 16px;
    border:none;
    border-bottom:1px solid #fff;
    background-color: $colorGrayMiddle;
    color: #fff;
    &::after{
        border-top:1px solid #fff;
        border-right:1px solid #fff;
    }
  }
  .header__cta-a--takuhai{
    display:block;
    text-align:left;
    padding:14px 12px 14px 16px;
    border:none;
    border-bottom:1px solid #fff;
    background-color:$colorMain;
    color: #fff;
    &::after{
        border-top:1px solid #fff;
        border-right:1px solid #fff;
    }
  }
  .header__cta-a--line{
    display:block;
    text-align:left;
    padding:14px 12px 14px 16px;
    border:none;
    border-bottom:1px solid #fff;
    background-color: $colorLine;
    color:#fff;
    &::after{
        border-top:1px solid #fff;
        border-right:1px solid #fff;
    }
  }
  .header__sp-menu{
    display:block;
    float:right;
  }
  .header__sp-menu-btn{
    display: block;
    box-sizing: border-box;
    outline: none;
    //border-radius: 4px;
    padding: 0.5em 1em 0.5em 1em;
    text-align: center;
    text-decoration: none;
    position:relative;
    font-size:14px;
    cursor: pointer;
    border: 1px solid $colorGrayDark;
    color: $colorGrayDark;
    &:hover{
      opacity: 0.7;
      text-decoration: none;
    }
  }
}
//===========================================
// .gnav
//===========================================
.gnav{
  background-color:$colorGrayDark;
  min-width:$max-width;
}
.gnav__inner{
  width:$max-width;
  margin:0 auto;
  padding:0;
  display:flex;
  justify-content:space-between;
}
.gnav__li{
  list-style:none;
  margin:0;
  padding:0;
}
.gnav__link{
  display:inline-block;
  padding:16px 12px;
  color:#fff;
  text-decoration: none;
  font-size:14px;
  position:relative;
}
.gnav__link:hover::after,
.gnav__link.is-current::after,
.gnav.gnav--top .gnav__link--top::after,
.gnav.gnav--gold .gnav__link--gold::after,
.gnav.gnav--takuhai .gnav__link--takuhai::after,
.gnav.gnav--takuhai_complete .gnav__link--takuhai::after,
.gnav.gnav--takuhai .gnav__link--takuhai::after,
.gnav.gnav--line .gnav__link--line::after,
.gnav.gnav--mail .gnav__link--mail::after,
.gnav.gnav--mail .gnav__link--mail::after,
.gnav.gnav--flow .gnav__link--flow::after,
.gnav.gnav--jewelry .gnav__link--jewelry::after,
body.body--blog .gnav__link--blog::after,
body.body--single .gnav__link--blog::after,
body.body--archive .gnav__link--blog::after,
body.body--search-results .gnav__link--blog::after{
  content:"";
  position:absolute;
  bottom:0;
  left:4px;
  right:4px;
  height:4px;
  background:$colorMain;
}

@media screen and (max-width: $tb-width) {
  .gnav{
    min-width:auto;
    display:none;
    background-color: $colorGrayDark;
  }
  .gnav__inner{
    width:auto;
    padding:0 ;
    display:block;
  }
  .gnav__li{
    list-style:none;
    margin:0;
    padding:0;
  }
  .gnav__link{
    display:block;
    padding:14px 12px 14px 16px;
    color:#fff;
    text-decoration: none;
    font-size:14px;
    border-bottom: 1px solid #fff;
    position:relative;
    &::before{
        content:"";
        width:10px;
        height:10px;
        border-top:1px solid #333;
        border-right:1px solid #333;
        transform: rotate(45deg);
        position:absolute;
        top:50%;
        margin-top:-5px;
        right:16px;
        border-top:1px solid #fff;
        border-right:1px solid #fff;
    }

  }
  .gnav__link:hover::after,
  .gnav__link.is-current::after,
  .gnav.gnav--top .gnav__link--top::after,
  .gnav.gnav--gold .gnav__link--gold::after,
  .gnav.gnav--takuhai .gnav__link--takuhai::after,
  .gnav.gnav--takuhai_complete .gnav__link--takuhai::after,
  .gnav.gnav--takuhai .gnav__link--takuhai::after,
  .gnav.gnav--line .gnav__link--line::after,
  .gnav.gnav--mail .gnav__link--mail::after,
  .gnav.gnav--mail .gnav__link--mail::after,
  .gnav.gnav--flow .gnav__link--flow::after,
  .gnav.gnav--jewelry .gnav__link--jewelry::after,
  body.body--blog .gnav__link--blog::after,
  body.body--single .gnav__link--blog::after,
  body.body--archive .gnav__link--blog::after,
  body.body--search-results .gnav__link--blog::after{
    content:"";
    position:absolute;
    top:4px;
    bottom:4px;
    left:0px;
    right:auto;
    height:auto;
    width:4px;
    background:$colorMain;
  }
}


//===========================================
// .breadcrumb
//===========================================
.breadcrumb{
  margin-top:24px;
  min-width:$max-width;
}
.breadcrumb__inner{
  width:$max-width;
  margin:0 auto;
  @include fontsize(12);
}

@media screen and (max-width: $tb-width) {
  .breadcrumb{
    min-width:auto;
  }
  .breadcrumb__inner{
    width:auto;
  }
}
@media screen and (max-width: $sm-width) {
  .breadcrumb{
    display: none;
  }
}

//===========================================
// .content
//===========================================
.content{
  min-width:$max-width;
  padding:48px 0;//変更
  &.is-bg_orange{
    background-color:$colorMain;
    .content__header{
      &::after{
        background:#fff;
      }
    }
    .content__title{
      background-color:$colorMain;
      color:#fff;
    }
  }
  &.is-bg_gray{
    background-color:$colorGray;
    .content__header{
      &::after{
        background:$colorGrayDark;
      }
    }
    .content__title{
      background-color:$colorGray;
    }
  }
  &.is-bg_img-gold{
    background:#e1dab7 url(/wp-content/themes/custom_theme/img/bg_img-gold.png) 50% 50% no-repeat ;
    background-size: cover;
  }
  &.is-bg_img-jewelry{
    background:#f9edf6 url(/wp-content/themes/custom_theme/img/bg_img-jewelry.png) 50% 50% no-repeat ;
    background-size: contain;
  }
}
.content__inner{
  width:$max-width;
  margin:0 auto;
  padding:24px 0;
  padding: 0;//変更
}
.content__header{
  position:relative;
  text-align:center;
  padding: 24px 0;
  padding: 0;//変更
  margin-bottom:48px;//変更
  &::after{
    content: "";
    position:absolute;
    top:50%;
    left:0;
    width:100%;
    height:1px;
    background:$colorFont;
  }
}
.content__title{
  text-align:center;
  font-size:28px;
  font-weight:bold;
  font-family: Georgia, 'Hiragino Mincho ProN', 'Yu Mincho', serif;
  display:inline-block;
  background:#fff;
  position:relative;
  z-index:2;
  padding:0 24px;
  margin:0;
}
.content__body{
  padding:24px 0 ;
  padding: 0;//変更
  &.is-gray{
    padding:24px 0;//変更
    background-color:$colorGray;
  }
}
.content__subtitle{
  font-size:24px;
  font-weight:normal;
  text-align:center;
  margin:0;
}
.content__description{
  text-align:center;
  margin:8px 0 0;
}

@media screen and (max-width: $tb-width) {
  .content{
    min-width:auto;
    padding:24px 0;
    &.is-bg_img-jewelry{
      background-size: cover;
      background-position: right bottom;
    }
  }
  .content__inner{
    width:auto;
    margin:0 12px;
  }
  .content__header{
    margin-bottom:24px;
    padding-left:24px;
    padding-right:24px;
  }
  .content__body{
    &.is-gray{
      padding:24px 12px;
    }
  }
}
@media screen and (max-width: $sm-width) {
  .content__title{
    font-size:20px;
    text-align:left;
    br{
      display:none;
    }
  }
  .content__subtitle{
    font-size:18px;
    font-weight: bold;
    text-align:center;
    padding-left:24px;
    padding-right:24px;
    br{
      display:none;
    }
  }
  .content__description{
    text-align:left;
  }
}
//===========================================
// .column
//===========================================

.column__row{
  display: flex;
  //margin-left: $margin * (-1);
  &.is-5point{
    background:
      url(/wp-content/themes/custom_theme/img/bg_5point_01.png) left bottom no-repeat,
      url(/wp-content/themes/custom_theme/img/bg_5point_02.png) right bottom no-repeat
  }
}

@mixin column($col,$maxcol){
  //width:calc(#{(($max-width + $margin ) * $col / $maxcol )} - #{$margin}) ;
	width :calc((( #{$max-width} + #{$margin} ) / #{$maxcol} * #{$col} ) - #{$margin} );
	width :calc((( 100% + #{$margin} ) / #{$maxcol} * #{$col} ) - #{$margin} );
}
@mixin columnOffset($col,$maxcol){
  //width:calc(#{(($max-width + $margin ) * $col / $maxcol )} - #{$margin}) ;
	//margin-left :calc(((( #{$max-width} + #{$margin} ) / #{$maxcol} ) * #{$col} ) - #{$margin} );
	margin-left :calc(((( #{$max-width} + #{$margin} ) / #{$maxcol} ) * #{$col} )) !important;
	margin-left :calc(((( 100% + #{$margin} ) / #{$maxcol} ) * #{$col} )) !important;
}

// basic setting
$maxcol:12; //カラム数
@for $i from 1 through $maxcol {
  .column__col--#{$i}of#{$maxcol} {
    @include column($i,$maxcol);
    &.is-bg_white{
      background-color:#fff;
    }
  }
  .column__col--#{$i}of#{$maxcol}:not(:first-child) {
    margin-left: $margin;
  }
}
@for $i from 1 through $maxcol {
  .column__offset--#{$i}of#{$maxcol} {
    @include columnOffset($i,$maxcol);
  }
}

$maxcol:5; //カラム数
@for $i from 1 through $maxcol {
  .column__col--#{$i}of#{$maxcol} {
    @include column($i,$maxcol);
    &.is-bg_white{
      background-color:#fff;
    }
  }
  .column__col--#{$i}of#{$maxcol}:not(:first-child) {
    margin-left: $margin;
  }
}
@for $i from 1 through $maxcol {
  .column__offset--#{$i}of#{$maxcol} {
    @include columnOffset($i,$maxcol);
  }
}

$maxcol:7; //カラム数
@for $i from 1 through $maxcol {
  .column__col--#{$i}of#{$maxcol} {
    @include column($i,$maxcol);
    &.is-bg_white{
      background-color:#fff;
    }
  }
  .column__col--#{$i}of#{$maxcol}:not(:first-child) {
    margin-left: $margin;
  }
}
@for $i from 1 through $maxcol {
  .column__offset--#{$i}of#{$maxcol} {
    @include columnOffset($i,$maxcol);
  }
}

@media screen and (min-width: $sm-width) and (max-width: $tb-width) {
  .column__row{
    &.is-5point{
      background-image:none;
    }
  }

  .column__row:not(.is-break-sm){
    display: block;
    [class*=column__col]{
      margin-left:0;
      width:auto;
    }
    [class*=column__col]:not(:first-child):not(:empty){
      margin-top:24px;
      margin-left:0;
    }
    [class*=column__offset]{
      margin-left:0 !important;
    }
  }
}

@media screen and (max-width: $sm-width) {
  .column__row{
    display: block;
    [class*=column__col]{
      margin-left:0;
      width:auto;
    }
    [class*=column__col]:not(:first-child):not(:empty){
      margin-top:24px;
      margin-left:0;
    }
    [class*=column__offset]{
      margin-left:0 !important;
    }
  }
}
//===========================================
// .sidenav
//===========================================
.sidenav--blog{
    margin-bottom:48px;
}
.sidenav__ttl{
  border-bottom:2px solid $colorGrayDark;
  font-weight:bold;
}
.sidenav > ul{
  padding-left:20px;
}
.sidenav > ul > li{
  @include fontsize(14);
}

@media screen and (max-width: $tb-width) {
  .sidenav--blog {
      margin-top: 48px;
      margin-bottom: 0;
  }
}

//===========================================
// .gnav--bottom
//===========================================
.gnav--bottom{
  background-color:$colorGray;
  min-width:$max-width;
  margin-top:48px;
}
.gnav--bottom__inner{
  width:$max-width;
  margin:0 auto;
  position:relative;
}
.gnav--bottom__pagetop{
  position:absolute;
  top:-26px;
  right: 0;
  padding:5px 12px;
  font-size:12px;
  background-color:$colorGray;
  color:$colorGrayDark;
  text-decoration: none;
}
.gnav--bottom__ul{
  margin:0;
  padding:0;
  display:flex;
  justify-content:center;
}
.gnav--bottom__li{
  list-style:none;
  margin:0;
  padding:0;
}
.gnav--bottom__link{
  display:inline-block;
  padding:8px 12px;
  color:$colorGrayDark;
  text-decoration: none;
  font-size:14px;
}

@media screen and (max-width: $tb-width) {
  .gnav--bottom{
    min-width:auto;
    margin-top:48px;
  }
  .gnav--bottom__inner{
    width:100%;
  }
  .gnav--bottom__pagetop{
    display:none;
  }
  .gnav--bottom__ul{
    display:block;
    border-top:1px solid #fff;
  }
  .gnav--bottom__li{
    list-style:none;
    margin:0;
    padding:0;
  }
  .gnav--bottom__link{
    display:block;
    padding:14px 12px;
    color:$colorGrayDark;
    text-decoration: none;
    font-size:14px;
    border-bottom:1px solid #fff;
    position:relative;
    &::before{
        content:"";
        width:10px;
        height:10px;
        border-top:1px solid #333;
        border-right:1px solid #333;
        transform: rotate(45deg);
        position:absolute;
        top:50%;
        margin-top:-5px;
        right:16px;
    }
  }
}

//===========================================
// .footer
//===========================================

.footer{
  background-color:$colorGrayDark;
  min-width:$max-width;
}
.footer__inner{
  width:$max-width;
  margin:0 auto;
  padding:48px 0 12px;
}
.footer__sitelogo{

}
.footer__profile{
  margin:24px 0 0;
  padding:0;
}
.footer__profile-li{
  list-style:none;
  padding:0;
  padding:0;
  color:#fff;
  font-size:14px;
  font-weight:normal;
  margin-top:4px;
}
.footer__map{
  height:225px;
  margin:0;
  padding:0;
}
.footer_map-link{
  color:#fff !important;
}
.footer__copy{
  color:#fff;
  font-size:12px;
  text-align:center;
  margin-top:24px;
}

@media screen and (max-width: $tb-width) {
  .footer{
    min-width:auto;
  }
  .footer__inner{
    width:100%;
    margin:0 auto;
    padding:24px 12px 12px;
    box-sizing: border-box;
  }
}


//===========================================
// .util
//===========================================
.util__mt--1unit{
  margin-top:24px !important;
}
.util__mt--2unit{
  margin-top:48px !important;
}
.util__pt--1unit{
  padding-top:24px !important;
}
.util__pb--1unit{
  padding-bottom:24px !important;
}
.util__ta--c{
  text-align:center !important;
}
.util__w--60{
  width:60px !important;
}
.util__w--72{
  width:72px !important;
}
.util__w--84{
  width:84px !important;
}
.util__w--96{
  width:96px !important;
}
.util__w--108{
  width:108px !important;
}
.util__w--120{
  width:120px !important;
}
.util__w--132{
  width:132px !important;
}
.util__w--144{
  width:144px !important;
}
.util__w--156{
  width:156px !important;
}
.util__w--168{
  width:168px !important;
}
.util__w--192{
  width:192px !important;
}
.util__w--204{
  width:204px !important;
}
.util__w--216{
  width:216px !important;
}
.util__w--220{
  width:220px !important;
}
.util__w--228{
  width:228px !important;
}
.util__w--240{
  width:240px !important;
}
.util__w--256{
  width:256px !important;
}
.util__w--268{
  width:268px !important;
}
.util__w--280{
  width:280px !important;
}
.util__w--292{
  width:292px !important;
}
.util__w--304{
  width:304px !important;
}

@media screen and (max-width: $tb-width) {
  .util__sp-img{
    display:block;
    width:100%;
    max-width: 100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
  }
  .util__sp-mt__0unit{
    margin-top:0 !important;
  }
}
//===========================================
// .txt
//===========================================

.txt--important{
  color:$colorMain !important;
}
.txt--center{
  text-align:center !important;
}
.txt--20b{
  font-size:20px !important;
  font-weight:bold !important;
}
.txt--14{
  font-size:14px !important;
}
.txt--12{
  font-size:12px !important;
}

//===========================================
// .table--01
//===========================================
.table--01{
  width:100%;
  border-collapse:collapse;
}
.table--01__tr{
  border-top:1px solid #bababa;
}
.table--01__th{
  background:$colorGrayDark;
  color:#fff;
  font-size:18px;
  font-weight:bold;
  padding:16px;
  box-sizing: border-box;
  &.is-gold{
    background:$colorGold;
    color:$colorFont;
  }
  &.is-pt{
    background:$colorPt;
    color:$colorFont;
  }
  &.is-silver{
    background:$colorSilver;
    color:$colorFont;
  }
}
.table--01__td{
  background:#fff;
  padding:16px;
  box-sizing: border-box;
}
.table--01__td-c{
  background:#fff;
  padding:16px;
  text-align:center;
  box-sizing: border-box;
}
.table--01__td-b{
  background:#fff;
  padding:16px;
  font-weight:bold;
  box-sizing: border-box;
}

//===========================================
// .table--02
//===========================================
.table--02{
  width:100%;
  border-collapse:collapse;
}
.table--02__tr{
  border-top:1px solid #bababa;
}
.table--02__th{
  vertical-align:top;
  font-weight:bold;
  padding:18px 16px;
  box-sizing: border-box;

}
.table--02__td{
  padding:8px 16px;
  box-sizing: border-box;
}
@media screen and (max-width: $sm-width) {
  .table--02{
    display:block;
  }
  .table--02__tr{
    display:block;
  }
  .table--02__th{
    display:block;
    padding-bottom:0;
  }
  .table--02__td{
    display:block;
  }
  .table--02__td.util__w--168{
    float:left;
    width:auto !important;
  }
  .table--02__td.util__w--168 + .table--02__td{
    float:left;
    padding-top:0;
  }
}
